<template>
  <Card title="考核记录">
    <a-form-model layout="inline" @keyup.enter.native="query" style="margin-bottom: 2vh">
      <a-form-model-item>
        <a-input v-model="form.name" placeholder="姓名" />
      </a-form-model-item>

      <a-form-model-item>
        <a-range-picker
          style="width: 300px"
          :placeholder="['考核开始日期', '考核开始日期']"
          v-model="form.date1"
        />
      </a-form-model-item>

      <a-form-model-item>
        <a-range-picker
          style="width: 300px"
          :placeholder="['考核结束日期', '考核结束日期']"
          v-model="form.date2"
        />
      </a-form-model-item>

      <a-form-model-item>
        <a-space>
          <a-button type="primary" @click="query">查询</a-button>
          <a-button @click="reset">重置</a-button>
        </a-space>
      </a-form-model-item>
    </a-form-model>

    <a-table
      bordered
      :dataSource="list"
      @change="onChange"
      :loading="loading"
      :pagination="{
            total,
            current,
            pageSize,
            showTotal: (total) => `共 ${total} 条记录`,
        }"
      rowKey="id"
    >
      <a-table-column key="userName" title="姓名" data-index="userName" />

      <a-table-column key="timeBegin" title="考核开始日期" data-index="timeBegin" align="center" />
      <a-table-column key="timeEnd" title="考核结束日期" data-index="timeEnd" align="center" />
      <a-table-column key="taskNumPlan" title="指派任务数量" data-index="taskNumPlan" align="right" />
      <a-table-column key="taskNumDone" title="实际完成数量" data-index="taskNumDone" align="right" />
      <a-table-column key="finishingRate" title="任务完成率" align="right">
        <template slot-scope="text">{{ text.finishingRate * 100 + '%' }}</template>
      </a-table-column>

      <a-table-column key="appraisal" title="评价" align="center">
        <template slot-scope="text">
          <span style="color: var(--theme-color)">{{ text.appraisal }}</span>
        </template>
      </a-table-column>
    </a-table>
  </Card>
</template>
  
<script>
import { fetchList } from "../api/log";

export default {
  data() {
    return {
      form: {},
      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,
    };
  },

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      this.loading = true;
      const { current, pageSize } = this;

      let timeBeginGt, timeBeginLt, timeEndGt, timeEndLt;

      if (Array.isArray(this.form.date1) && this.form.date1.length === 2) {
        timeBeginGt = this.form.date1[0].format("YYYY-MM");
        timeBeginLt = this.form.date1[1].format("YYYY-MM");
      }
      if (Array.isArray(this.form.date2) && this.form.date2.length === 2) {
        timeEndGt = this.form.date2[0].format("YYYY-MM");
        timeEndLt = this.form.date2[1].format("YYYY-MM");
      }

      fetchList({
        pageNum: current,
        pageSize: pageSize,

        name: this.form.name,
        timeBeginGt,
        timeBeginLt,
        timeEndGt,
        timeEndLt,
      })
        .then((res) => {
          if (Array.isArray(res.list)) {
            this.list = Object.freeze(res.list);
            this.total = res.totalSize || 0;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.getList();
    },

    query() {
      this.current = 1;
      this.getList();
    },
    reset() {
      this.form = {};
      this.current = 1;
      this.getList();
    },
  },
};
</script>